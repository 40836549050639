import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223'),
	() => import('./nodes/224'),
	() => import('./nodes/225'),
	() => import('./nodes/226'),
	() => import('./nodes/227'),
	() => import('./nodes/228'),
	() => import('./nodes/229'),
	() => import('./nodes/230'),
	() => import('./nodes/231'),
	() => import('./nodes/232'),
	() => import('./nodes/233'),
	() => import('./nodes/234'),
	() => import('./nodes/235'),
	() => import('./nodes/236'),
	() => import('./nodes/237'),
	() => import('./nodes/238'),
	() => import('./nodes/239'),
	() => import('./nodes/240'),
	() => import('./nodes/241'),
	() => import('./nodes/242'),
	() => import('./nodes/243'),
	() => import('./nodes/244'),
	() => import('./nodes/245'),
	() => import('./nodes/246'),
	() => import('./nodes/247'),
	() => import('./nodes/248'),
	() => import('./nodes/249'),
	() => import('./nodes/250'),
	() => import('./nodes/251'),
	() => import('./nodes/252'),
	() => import('./nodes/253'),
	() => import('./nodes/254'),
	() => import('./nodes/255'),
	() => import('./nodes/256'),
	() => import('./nodes/257'),
	() => import('./nodes/258'),
	() => import('./nodes/259'),
	() => import('./nodes/260'),
	() => import('./nodes/261'),
	() => import('./nodes/262'),
	() => import('./nodes/263'),
	() => import('./nodes/264'),
	() => import('./nodes/265'),
	() => import('./nodes/266'),
	() => import('./nodes/267'),
	() => import('./nodes/268'),
	() => import('./nodes/269'),
	() => import('./nodes/270'),
	() => import('./nodes/271'),
	() => import('./nodes/272'),
	() => import('./nodes/273'),
	() => import('./nodes/274'),
	() => import('./nodes/275'),
	() => import('./nodes/276'),
	() => import('./nodes/277'),
	() => import('./nodes/278'),
	() => import('./nodes/279'),
	() => import('./nodes/280'),
	() => import('./nodes/281'),
	() => import('./nodes/282'),
	() => import('./nodes/283'),
	() => import('./nodes/284'),
	() => import('./nodes/285'),
	() => import('./nodes/286'),
	() => import('./nodes/287'),
	() => import('./nodes/288'),
	() => import('./nodes/289'),
	() => import('./nodes/290'),
	() => import('./nodes/291'),
	() => import('./nodes/292'),
	() => import('./nodes/293')
];

export const server_loads = [0,40,36,17,37,18,19,20,4,6,7,10,25,28,14];

export const dictionary = {
		"/(marketing)": [227,[30]],
		"/(marketing)/about": [228,[30,31]],
		"/(admin)/admin": [48,[2]],
		"/(admin)/admin/abundant/add-update": [~49,[2]],
		"/(admin)/admin/abundant/duplicate-risk-type": [~50,[2]],
		"/(admin)/admin/abundant/evict-file-cache": [~51,[2]],
		"/(admin)/admin/abundant/groups": [~52,[2]],
		"/(admin)/admin/abundant/migrate-db": [~53,[2]],
		"/(admin)/admin/abundant/open-api": [54,[2]],
		"/(admin)/admin/abundant/queries-sg": [~55,[2]],
		"/(admin)/admin/abundant/reset-db": [~56,[2]],
		"/(admin)/admin/abundant/sync-indexes-to-db": [~57,[2]],
		"/(admin)/admin/abundant/sync-indexes-to-disk": [~58,[2]],
		"/(admin)/admin/abundant/sync-validation-to-db": [~59,[2]],
		"/(admin)/admin/abundant/sync-validation-to-disk": [~60,[2]],
		"/(admin)/admin/categories/add": [~61,[2]],
		"/(admin)/admin/charts/bar": [~62,[2]],
		"/(admin)/admin/charts/map": [63,[2]],
		"/(admin)/admin/dev-ops/analytics.calendar-month": [~64,[2]],
		"/(admin)/admin/dev-ops/analytics.items-per-month-full-load": [~65,[2]],
		"/(admin)/admin/dev-ops/analytics.items-per-month-incremental-load": [~66,[2]],
		"/(admin)/admin/dev-ops/find-unused-files": [~67,[2]],
		"/(admin)/admin/dev-ops/run-pipelines": [~68,[2]],
		"/(admin)/admin/misc/add-analytics-board": [~69,[2]],
		"/(admin)/admin/misc/create-thumbnails-by-id": [~70,[2]],
		"/(admin)/admin/misc/customer-scripts": [~71,[2]],
		"/(admin)/admin/misc/get-asset-info": [~72,[2]],
		"/(admin)/admin/misc/inspect-devalue": [73,[2]],
		"/(admin)/admin/misc/sendgrid-delete-contacts": [~74,[2]],
		"/(admin)/admin/risk-data/copy-risk-model-to-prod": [~75,[2]],
		"/(admin)/admin/risk-data/copy-risk-type": [~76,[2]],
		"/(admin)/admin/test-email": [~77,[2]],
		"/(admin)/admin/users/assign-permissions": [~78,[2]],
		"/(admin)/admin/users/delete": [~79,[2]],
		"/(admin)/admin/users/end-session": [~80,[2]],
		"/(admin)/admin/users/seed": [~81,[2]],
		"/(admin)/admin/users/set-user-tasks": [~82,[2]],
		"/(admin)/admin/users/toggle-21risk-admin": [~83,[2]],
		"/(marketing)/blog": [229,[30,32]],
		"/(marketing)/blog/[slug]": [230,[30,32]],
		"/(marketing)/changelog": [231,[30]],
		"/(marketing)/changelog/[slug]": [232,[30]],
		"/(marketing)/checklists": [~233,[30]],
		"/(marketing)/checklists/success": [237,[30]],
		"/(marketing)/checklists/view": [238,[30]],
		"/(marketing)/checklists/[_id=objectId]": [~234,[30]],
		"/(marketing)/checklists/[_id=objectId]/print": [~235,[30]],
		"/(marketing)/checklists/[_id=objectId]/request": [~236,[30]],
		"/(docs)/compliance": [221,[29]],
		"/(docs)/compliance/[...rest]": [~222,[29]],
		"/(marketing)/contact": [239,[30]],
		"/(marketing)/contact/sales": [~240,[30]],
		"/(marketing)/contact/sales/success": [241,[30]],
		"/(marketing)/customers": [242,[30,33]],
		"/(marketing)/customers/BHJ": [243,[30,33]],
		"/(marketing)/customers/[slug]": [244,[30,33]],
		"/(docs)/docs": [223,[29]],
		"/(docs)/docs/concepts": [225,[29]],
		"/(docs)/docs/concepts/[...rest]": [226,[29]],
		"/(docs)/docs/[...rest]": [~224,[29]],
		"/(reset)/emails/preview/[templateId]/[sendgridPayload]": [~277,[40]],
		"/(marketing)/experts": [245,[30,34]],
		"/(marketing)/experts/[slug]": [~246,[30,34,35]],
		"/(marketing)/experts/[slug]/success": [247,[30,34,35]],
		"/(marketing)/features/actions": [248,[30]],
		"/(marketing)/features/audits": [249,[30]],
		"/(unauthed)/feedback/emails/thank-you": [284,[42,43]],
		"/(unauthed)/feedback/emails/[emailId]": [~283,[42,43]],
		"/(marketing)/health": [250,[30]],
		"/(marketing)/help": [~251,[30,36]],
		"/(marketing)/help/success": [252,[30,36]],
		"/(marketing)/legal": [253,[30]],
		"/(marketing)/legal/[slug]": [254,[30]],
		"/(unauthed)/library-public": [285,[42]],
		"/(unauthed-prerender)/login": [~289,[44,45]],
		"/(unauthed-prerender)/login/check": [~290,[44,45]],
		"/(authed)/logout": [~220,[17]],
		"/(admin)/mgmt": [84,[3]],
		"/(admin)/mgmt/analytics/overview": [~85,[3]],
		"/(unauthed)/misc/debug": [286,[42]],
		"/(unauthed)/misc/files-list": [~287,[42]],
		"/(marketing)/newsletter/check": [255,[30]],
		"/(marketing)/newsletter/subscribe": [~256,[30]],
		"/(marketing)/newsletter/success": [257,[30]],
		"/(marketing)/newsletter/verify": [~258,[30]],
		"/(unauthed)/no-access/[tenant_without_access=org_slug]": [~288,[42]],
		"/(reset)/onboarding": [278,[40,41]],
		"/(reset)/onboarding/1": [279,[40,41]],
		"/(reset)/onboarding/2": [~280,[40,41]],
		"/(reset)/onboarding/3": [~281,[40,41]],
		"/(reset)/onboarding/4": [282,[40,41]],
		"/(marketing)/partners": [~259,[30]],
		"/(marketing)/partners/success": [260,[30]],
		"/(marketing)/pricing": [261,[30]],
		"/(marketing)/roadmap": [262,[30]],
		"/(unauthed-prerender)/signup": [~291,[44,46]],
		"/(unauthed-prerender)/signup/check": [~292,[44,46]],
		"/(unauthed-prerender)/signup/complete": [~293,[44,46]],
		"/(marketing)/solutions/compliance": [263,[30]],
		"/(marketing)/solutions/insurance-values": [264,[30]],
		"/(marketing)/solutions/natcat": [265,[30]],
		"/(marketing)/solutions/natcat/[hazard]": [~266,[30]],
		"/(marketing)/solutions/property-insurance": [~267,[30]],
		"/(marketing)/solutions/property-insurance/roi-breakdown": [~268,[30]],
		"/(marketing)/solutions/property-insurance/roi-breakdown/success": [269,[30]],
		"/(marketing)/solutions/property-insurance/roi-calculator": [~270,[30]],
		"/(marketing)/updates": [271,[30,37]],
		"/(marketing)/updates/[slug]": [272,[30,37]],
		"/(marketing)/whitepapers": [273,[30,38]],
		"/(marketing)/whitepapers/success": [275,[30,38]],
		"/(marketing)/whitepapers/view/[uuid]": [276,[30,38]],
		"/(marketing)/whitepapers/[slug]": [~274,[30,38,39]],
		"/(authed)/[tenant=org_slug]/actions": [~138,[17,18,19]],
		"/(authed)/[tenant=org_slug]/actions/board": [140,[17,18,19]],
		"/(authed)/[tenant=org_slug]/actions/settings": [141,[17,18,19,20]],
		"/(authed)/[tenant=org_slug]/actions/settings/fields/new": [~143,[17,18,19,20]],
		"/(authed)/[tenant=org_slug]/actions/settings/fields/[field]": [~142,[17,18,19,20]],
		"/(authed)/[tenant=org_slug]/actions/table": [~144,[17,18,19]],
		"/(authed)/[tenant=org_slug]/actions/[view]": [139,[17,18,19]],
		"/(authed)/[tenant=org_slug]/action/[itemId]": [~137,[17,18]],
		"/(app)/[tenant=org_slug]/analytics/[board=board_slug]/[[page]]": [~86,[4]],
		"/(authed)/[tenant=org_slug]/automations/actions": [~145,[17,18,21]],
		"/(authed)/[tenant=org_slug]/automations/actions/new": [~147,[17,18,21]],
		"/(authed)/[tenant=org_slug]/automations/actions/[_id=objectId]": [~146,[17,18,21]],
		"/(authed)/[tenant=org_slug]/automations/models/[riskModelId=objectId]": [~148,[17,18,21]],
		"/(authed)/[tenant=org_slug]/automations/models/[riskModelId=objectId]/new": [~150,[17,18,21]],
		"/(authed)/[tenant=org_slug]/automations/models/[riskModelId=objectId]/[_id=objectId]": [~149,[17,18,21]],
		"/(authed)/[tenant=org_slug]/a/[reportId]/[[page]]": [~136,[17,18]],
		"/(app)/[tenant=org_slug]/compliance": [87,[4]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions": [88,[4,5,6]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/board": [90,[4,5,6]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/settings": [91,[4,5,6,7]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/settings/fields/new": [~93,[4,5,6,7]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/settings/fields/[field]": [~92,[4,5,6,7]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/table": [~94,[4,5,6]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/[view]": [89,[4,5,6]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/analytics": [~95,[4,5,8]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit": [~96,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/automations/new": [~102,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/automations/[_id=objectId]": [~101,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/[version]": [~97,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/[version]/automations": [~98,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/[version]/automations/new": [~100,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/[version]/automations/[_id=objectId]": [~99,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports": [~103,[4,5,9]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/new": [~106,[4,5,9]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/settings": [107,[4,5,9,10]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/settings/fields/new": [~109,[4,5,9,10]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/settings/fields/[field]": [~108,[4,5,9,10]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/[_id=objectId]": [~104,[4,5,9]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/[view]": [~105,[4,5,9]],
		"/(authed)/[tenant=org_slug]/dashboard": [~151,[17,18]],
		"/(authed)/[tenant=org_slug]/dashboard/empty": [154,[17,18]],
		"/(authed)/[tenant=org_slug]/dashboard/[riskTypeId=objectId]": [~153,[17,18]],
		"/(authed)/[tenant=org_slug]/dashboard/[...rest]": [152,[17,18]],
		"/(authed)/[tenant=org_slug]/debug": [155,[17,18,22]],
		"/(authed)/[tenant=org_slug]/debug/config/print-values": [156,[17,18,22]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-10": [159,[17,18,22]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-11": [160,[17,18,22]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-12": [161,[17,18,22]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-1": [~158,[17,18,22]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-2": [~162,[17,18,22]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-30": [~164,[17,18,22]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-31": [~165,[17,18,22]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-32": [~166,[17,18,22]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-3": [163,[17,18,22]],
		"/(authed)/[tenant=org_slug]/debug/errors/error-example-5": [~167,[17,18,22]],
		"/(authed)/[tenant=org_slug]/debug/errors/[...0]intro": [157,[17,18,22]],
		"/(authed)/[tenant=org_slug]/debug/forms/[...0]intro": [168,[17,18,22]],
		"/(authed)/[tenant=org_slug]/file-upload": [~169,[17,18]],
		"/(app)/[tenant=org_slug]/folder/[board=board_slug]": [110,[4,11]],
		"/(app)/[tenant=org_slug]/folder/[board=board_slug]/edit": [~111,[4,11]],
		"/(app)/[tenant=org_slug]/getting-started": [~112,[4]],
		"/(authed)/[tenant=org_slug]/help": [170,[17,18]],
		"/(app)/[tenant=org_slug]/home": [~113,[4]],
		"/(authed)/[tenant=org_slug]/insurance/get-started": [172,[17,18]],
		"/(authed)/[tenant=org_slug]/insurance/[[page]]": [171,[17,18]],
		"/(authed)/[tenant=org_slug]/landing-page": [173,[17,18]],
		"/(authed)/[tenant=org_slug]/library": [~174,[17,18]],
		"/(authed)/[tenant=org_slug]/library/edit/[_id=objectId]": [~176,[17,18]],
		"/(authed)/[tenant=org_slug]/library/new": [~177,[17,18]],
		"/(authed)/[tenant=org_slug]/library/[_id=objectId]": [~175,[17,18]],
		"/(authed)/[tenant=org_slug]/manage-data": [~178,[17,18,23]],
		"/(authed)/[tenant=org_slug]/manage-data/locations": [~179,[17,18,23,24]],
		"/(authed)/[tenant=org_slug]/manage-data/locations/import": [~181,[17,18,23,24]],
		"/(authed)/[tenant=org_slug]/manage-data/locations/map": [~182,[17,18,23,24]],
		"/(authed)/[tenant=org_slug]/manage-data/locations/settings": [183,[17,18,23,24,25]],
		"/(authed)/[tenant=org_slug]/manage-data/locations/settings/fields/new": [~185,[17,18,23,24,25]],
		"/(authed)/[tenant=org_slug]/manage-data/locations/settings/fields/[field]": [~184,[17,18,23,24,25]],
		"/(authed)/[tenant=org_slug]/manage-data/locations/table": [~186,[17,18,23,24]],
		"/(authed)/[tenant=org_slug]/manage-data/locations/tree": [~187,[17,18,23,24]],
		"/(authed)/[tenant=org_slug]/manage-data/locations/[siteId]": [~180,[17,18,23,24]],
		"/(authed)/[tenant=org_slug]/manage-data/tags": [~188,[17,18,23]],
		"/(authed)/[tenant=org_slug]/manage-data/tags/[tagId]": [~189,[17,18,23]],
		"/(authed)/[tenant=org_slug]/members": [~190,[17,18]],
		"/(authed)/[tenant=org_slug]/members/new": [~192,[17,18]],
		"/(authed)/[tenant=org_slug]/members/[user_id]": [~191,[17,18]],
		"/(authed)/[tenant=org_slug]/models": [193,[17,18]],
		"/(app)/[tenant=org_slug]/new/board": [~114,[4]],
		"/(app)/[tenant=org_slug]/new/folder": [~115,[4]],
		"/(app)/[tenant=org_slug]/new/model": [~116,[4]],
		"/(authed)/[tenant=org_slug]/profile": [~194,[17,18,26]],
		"/(authed)/[tenant=org_slug]/profile/account": [~195,[17,18,26]],
		"/(authed)/[tenant=org_slug]/profile/account/balance-changes": [~196,[17,18,26]],
		"/(authed)/[tenant=org_slug]/profile/account/roles/[roleId]": [~197,[17,18,26]],
		"/(authed)/[tenant=org_slug]/profile/billing": [198,[17,18,26]],
		"/(authed)/[tenant=org_slug]/profile/integrations": [199,[17,18,26]],
		"/(authed)/[tenant=org_slug]/profile/notifications": [200,[17,18,26]],
		"/(authed)/[tenant=org_slug]/reports/a": [~209,[17,18]],
		"/(authed)/[tenant=org_slug]/reports/request-audit": [~210,[17,18]],
		"/(authed)/[tenant=org_slug]/reports/settings": [211,[17,18,28]],
		"/(authed)/[tenant=org_slug]/reports/settings/fields/new": [~213,[17,18,28]],
		"/(authed)/[tenant=org_slug]/reports/settings/fields/[field]": [~212,[17,18,28]],
		"/(authed)/[tenant=org_slug]/reports/[[view]]": [~208,[17,18,27]],
		"/(authed)/[tenant=org_slug]/report/new": [~204,[17,18]],
		"/(authed)/[tenant=org_slug]/report/new/[riskType=objectId]": [~205,[17,18]],
		"/(authed)/[tenant=org_slug]/report/new/[riskType=objectId]/[site=objectId]": [~206,[17,18]],
		"/(authed)/[tenant=org_slug]/report/start/[_id=objectId]": [~207,[17,18]],
		"/(authed)/[tenant=org_slug]/report/[_id=objectId]": [~201,[17,18]],
		"/(authed)/[tenant=org_slug]/report/[_id=objectId]/print": [~202,[17,18]],
		"/(authed)/[tenant=org_slug]/report/[_id=objectId]/qr-code": [~203,[17,18]],
		"/(authed)/[tenant=org_slug]/risk-reports": [214,[17,18]],
		"/(authed)/[tenant=org_slug]/risk-reports/[_id]": [215,[17,18]],
		"/(app)/[tenant=org_slug]/settings/account": [~117,[4,12]],
		"/(app)/[tenant=org_slug]/settings/account/roles/[roleId]": [~118,[4,12]],
		"/(app)/[tenant=org_slug]/settings/profile": [~119,[4,12]],
		"/(authed)/[tenant=org_slug]/sign-in": [216,[17,18]],
		"/(app)/[tenant=org_slug]/sites": [~120,[4,13]],
		"/(app)/[tenant=org_slug]/sites/import": [~122,[4,13]],
		"/(app)/[tenant=org_slug]/sites/map": [~123,[4,13]],
		"/(app)/[tenant=org_slug]/sites/settings": [124,[4,13,14]],
		"/(app)/[tenant=org_slug]/sites/settings/fields/new": [~126,[4,13,14]],
		"/(app)/[tenant=org_slug]/sites/settings/fields/[field]": [~125,[4,13,14]],
		"/(app)/[tenant=org_slug]/sites/table": [~127,[4,13]],
		"/(app)/[tenant=org_slug]/sites/tree": [~128,[4,13]],
		"/(app)/[tenant=org_slug]/sites/[siteId=objectId]": [~121,[4,13]],
		"/(authed)/[tenant=org_slug]/support": [217,[17,18]],
		"/(app)/[tenant=org_slug]/tags": [~129,[4,15]],
		"/(app)/[tenant=org_slug]/tags/[tagId=objectId]": [~130,[4,15]],
		"/(app)/[tenant=org_slug]/task/[itemId]": [~131,[4]],
		"/(authed)/[tenant=org_slug]/test-error-load": [219,[17,18]],
		"/(authed)/[tenant=org_slug]/test-error": [218,[17,18]],
		"/(app)/[tenant=org_slug]/users": [~132,[4,16]],
		"/(app)/[tenant=org_slug]/users/new": [~134,[4,16]],
		"/(app)/[tenant=org_slug]/users/[user_id]": [~133,[4,16]],
		"/(authed)/[...rest]": [135,[17]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';